import Vue from 'vue'
import {
  Form,
  FormItem,
  Input,
  RadioGroup,
  Radio,
  Select,
  Option,
  Button,
  Pagination,
  Loading,
  MessageBox,
  Message,
  Upload,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Drawer,
  DatePicker,
  Image,
  Dialog,
  Tooltip, Table, TableColumn,
} from 'element-ui'
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Pagination)
Vue.use(Upload)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Drawer)
Vue.use(DatePicker)
Vue.use(Image)
Vue.use(Dialog)
Vue.use(Tooltip)

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
