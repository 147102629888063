import qs from 'qs'
import axios from 'axios'
import { MessageBox } from 'element-ui'
import router from '../router'

const service = axios.create({
  baseURL: '/', // process.env.NODE_ENV === 'production' ? 'http://192.168.30.171/' : '/',
  timeout: 10000,
  withCredentials: true,
  responseType: 'json',
  maxContentLength: 2000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Cache-Control': 'no-cache'
  },
  transformRequest: [
    data => {
      return qs.stringify(data)
    }
  ]
})
// 添加请求拦截器
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = token

    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 添加响应拦截器
service.interceptors.response.use(
  response => {
    if (response.status === 200 && response.data.code === 200) {
      return Promise.resolve(response.data || {})
    } else {
      if (response.data.code === 401) {
        MessageBox.alert('您登录超时或者未登录！')
          .then(() => {
            localStorage.removeItem('token')
            router.push('/login')
          })
        return false
      }
      return Promise.reject(response.data)
    }
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误'
          break

        case 401:
          error.message = '未授权，请登录'
          break

        case 403:
          error.message = '拒绝访问'
          break

        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`
          break

        case 408:
          error.message = '请求超时'
          break

        case 500:
          error.message = '服务器内部错误'
          break

        case 501:
          error.message = '服务未实现'
          break

        case 502:
          error.message = '网关错误'
          break

        case 503:
          error.message = '服务不可用'
          break

        case 504:
          error.message = '网关超时'
          break

        case 505:
          error.message = 'HTTP版本不受支持'
          break

        default:
      }
    } else {
      if (error.message === 'Network Error') {
        error.message = '接口网络异常，请检查网络或重新操作！'
      }
    }
    return Promise.reject(error)
  }
)
// get
export const _get = req => {
  return new Promise((resolve, reject) => {
    service.get(req.url, { params: req.data })
      .then((res) => {
        // todo
        resolve(res)
      })
      .catch((error) => {
        // todo
        reject(error)
      })
  })
}
// post
export const _post = req => {
  return service({
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [
      data => {
        return JSON.stringify(data)
      }
    ],
    method: 'post',
    url: `${req.url}`,
    data: req.data
  })
}
// post_qs
export const _postQs = req => {
  return service({ method: 'post', url: `${req.url}`, data: req.data })
}
// patch
export const _put = req => {
  return service({
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [
      data => {
        return JSON.stringify(data)
      }
    ],
    method: 'put',
    url: `${req.url}`,
    data: req.data
  })
}
// patch_qs
export const _putQs = req => {
  return service({ method: 'put', url: `${req.url}`, data: req.data })
}
// delete
export const _delete = req => {
  return service({ method: 'delete', url: `${req.url}`, data: req.data })
}
// post and no withCredentials
export const _postNoWithCredentials = req => {
  return service({
    method: 'post',
    url: `${req.url}`,
    data: req.data,
    withCredentials: false
  })
}

export default service
